import styled from 'styled-components'
import { size } from '../../constants'

export const DecorateImage = styled.img`
  position: absolute;
  top: -30px;
  right: 8px;
  display: block;
  width: 100%;
  max-width: 690px;

  @media (max-width: ${size.lg}) {
    top: 10px;
    right: 12px;
    max-width: 468px;
  }

  @media (max-width: ${size.md}) {
    top: 104px;
    max-width: 394px;
  }

  @media (max-width: ${size.sm}) {
    position: relative;
    left: 50%;
    top: unset;
    transform: translateX(-50%);
    max-width: 100%;
  }

  @media (max-width: ${size.xs375}) {
    padding-bottom: 0px;
  }
`
