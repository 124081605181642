import styled from 'styled-components'
import { Button } from '../../../atoms/Button'
import { size } from '../../../constants'

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  z-index: 3;

  @media (max-width: ${size.md}) {
    flex-direction: column;
    gap: 16px;
    width: 249px;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
  }
`
export const ButtonNewPrimary = styled(Button.NewPrimary)`
  z-index: 2;

  @media (max-width: ${size.md}) {
    width: 100%;
  }
`
export const ButtonNewOutline = styled(Button.NewOutline)`
  background-color: transparent;
  z-index: 2;
  white-space: nowrap;

  @media (max-width: ${size.md}) {
    width: 100%;
  }
`
