import React from 'react'

const RequirementsListItem = ({ title, desc }) => (
  <li className="requirements-list__item requirements-item">
    <h3 className="requirements-item__title h3">{title}</h3>
    <p className="requirements-item__desc">{desc}</p>
  </li>
)

export default RequirementsListItem
