import './index.scss'
import React from 'react'

const TeammatePlussesCard = ({ card: { img, alt, desc } }) => (
  <div className="designer-col col-12 col-sm-4 col-md-4 col-lg-4">
    <div className="teammate-plusses__card teammate-plusses-card">
      <img alt={alt} className="teammate-plusses-card__img" src={img} />
      <p className="teammate-plusses-card__desc">{desc}</p>
    </div>
  </div>
)

export default TeammatePlussesCard
