import React from 'react'
import { ButtonGroup, ButtonNewOutline, ButtonNewPrimary } from './index.styles'
import { beInTeam } from '../../../../static/db.json'

export const RecrutingButtonGroup = ({ hidden }) => {
  if (hidden) {
    return null
  }

  return (
    <ButtonGroup>
      <ButtonNewPrimary
        href={beInTeam.clickUp}
        target="_blank"
        type="externalLink"
      >
        Подать заявку
      </ButtonNewPrimary>
      <ButtonNewOutline
        href={`${process.env.GATSBY_SITEURL}/${beInTeam.pdf}`}
        target="_blank"
        type="externalLink"
      >
        Условия сотрудничества
      </ButtonNewOutline>
    </ButtonGroup>
  )
}
