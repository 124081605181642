import '../styles/BelnTeam.scss'
import 'swiper/components/pagination'
import 'swiper/swiper.scss'
import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React, { useCallback, useEffect, useRef } from 'react'
import RequirementsListItem from '../components/RequirementsListItem'
import SEO, { getSeoMedia } from '../atoms/SEO'
import Swiper from 'react-id-swiper'
import SwiperCore, { Pagination } from 'swiper'
import TeammatePlussesCard from '../components/TeammatePlussesCard'
import { BeInTeamIsActive } from '../organisms/BeInTeamHeaderSection'
import { BreadCrumbs, BreadcrumbSEO } from '../molecules/BreadCrumbs'
import { Button } from '../atoms/Button'
import { Event as GAEvent } from '../components/GA'
import { RecrutingButtonGroup } from '../organisms/BeInTeamHeaderSection/RecrutingButtonGroup'
import { beInTeam } from '../../static/db.json'
import {
  selectDeviceSize,
  selectDeviceSizeIsMobile
} from '../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

SwiperCore.use([Pagination])

const cards = [
  {
    img: '/img/icons/be-in-team/1.svg',
    alt: 'Собака спит на кресле рядом с растением в горшке',
    desc: 'Не нужно арендовать кабинет'
  },
  {
    img: '/img/icons/be-in-team/2.svg',
    alt: 'Ноутбук и чашка стоят перед окном, за которым видно символичное изображение эйфелевой башни',
    desc: 'Можно работать из любой точки мира'
  },
  {
    img: '/img/icons/be-in-team/3.svg',
    alt: 'Раскрытая книга с карандашом на фоне бумаг',
    desc: 'Сами решаете, сколько клиентов готовы взять'
  },
  {
    img: '/img/icons/be-in-team/4.svg',
    alt: 'Силуэты трех человек',
    desc: 'Попадаете в комьюнити профессионалов'
  },
  {
    img: '/img/icons/be-in-team/5.svg',
    alt: 'Четыре круга на экране монитора компьютера, рядом лежит мышка',
    desc: 'Проходите супервизии и интервизии'
  },
  {
    img: '/img/icons/be-in-team/6.svg',
    alt: 'Изображение телефона с открытым чатом',
    desc: 'Бесплатно обучаетесь онлайн-консультированию'
  }
]

const pausedPsychologistRecruiting = false

const BeInTeam = () => {
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  const isDocument = () => typeof document !== 'undefined'
  let root

  useEffect(() => {
    root = document.querySelector('.root')
  }, [])

  useEffect(() => {
    if (isDocument()) {
      root.scrollTo(0, 0)
      root.classList.remove('root_fixed')
    }
  }, [root])

  const wWidth = useSelector(selectDeviceSize)
  const paginationRef = useRef(null)

  const params = {
    loop: false,
    grabCursor: true,
    pagination: { clickable: true, type: 'bullets', el: paginationRef.current },
    slidesPerView: 1,
    spaceBetween: 20,
    preventClicks: false,
    preventClicksPropagation: false,
    on: {
      beforeInit: ({ params }) => {
        params.pagination.el = paginationRef.current
      }
    }
  }

  const handleNewOpenFormJob = useCallback((label) => {
    GAEvent.NewOpenFormJob(label)
  }, [])

  return (
    <>
      <App>
        <SEO
          children={<BreadcrumbSEO />}
          description="Вакансия психолога онлайн в сервисе YouTalk. Ведение видеоконсультаций и переписки с клиентами. Поддержка профессионального сообщества."
          images={getSeoMedia([
            {
              pathname: '/img/og/be-in-team.png',
              alt: 'be-in-team'
            }
          ])}
          title="Сотрудничество с YouTalk для психологов"
        />
        <Header />
        <BreadCrumbs />
        <main className="main">
          <BeInTeamIsActive paused={pausedPsychologistRecruiting} />
          <section className="teammate-plusses">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <RecrutingButtonGroup hidden={!isMobile} />
                  <h2 className="teammate-plusses__title h1">Сплошные плюсы</h2>
                </div>
                {wWidth < 768 ? (
                  <>
                    <Swiper
                      {...params}
                      containerClass="teammate-plusses-slider"
                    >
                      {cards.map((card, i) => (
                        <div
                          key={`teammate-plusses-sl${i}`}
                          className="teammate-plusses__item"
                        >
                          <TeammatePlussesCard card={card} />
                        </div>
                      ))}
                    </Swiper>
                    <div
                      ref={paginationRef}
                      className="teammate-plusses__slider-dots sl-dots"
                    ></div>
                  </>
                ) : (
                  cards.map((card, i) => (
                    <TeammatePlussesCard
                      key={`teammate-plusses${i}`}
                      card={card}
                    />
                  ))
                )}
              </div>
            </div>
            <img
              alt="Светло-синяя фигура"
              className="teammate-plusses__decor"
              src="/img/icons/be-in-team/teammate-plusses-decor.svg"
            />
          </section>

          <section className="requirements">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <h2 className="requirements__title h1">
                    Требования к&nbsp;психологам
                  </h2>
                  <ul className="requirements-list">
                    <RequirementsListItem
                      desc="Мы просим прислать сканы дипломов и&nbsp;сотрудничаем
												только с&nbsp;теми, у&nbsp;кого есть высшее
												психологическое образование."
                      title="Высшее образование"
                    />
                    <RequirementsListItem
                      desc="Мы доверяем программам повышения квалификации
												длительностью от 2&#8209;х лет и&nbsp;радуемся, когда
												специалист продолжает обучаться всю жизнь."
                      title="Обучение психотерапии"
                    />
                    <RequirementsListItem
                      desc="Мы сотрудничаем с психологами, которые консультируют
												не&nbsp;менее 3&#8209;х лет в&nbsp;индивидуальном,
												групповом или семейном формате."
                      title="Опыт работы с&nbsp;клиентами"
                    />
                    <RequirementsListItem
                      desc="Мы верим, что осознанность и&nbsp;мастерство терапевта
												эффективно развивается только в&nbsp;диалоге
												с&nbsp;коллегами."
                      title="Супервизия и интервизия"
                    />
                    <RequirementsListItem
                      desc="Для нас важно, чтобы психологи имели свой личный опыт
												прохождения психотерапии в&nbsp;качестве клиентов
												не&nbsp;менее 50&nbsp;часов."
                      title="Прохождение личной терапии"
                    />
                    <RequirementsListItem
                      desc="Нам важно, чтобы вы&nbsp;умели и&nbsp;были готовы
												работать в&nbsp;формате видеосессий, синхронной
												и&nbsp;асинхронной переписки."
                      title="Опыт работы в онлайн-формате"
                    />
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="join-us">
            <div className="container">
              <div className="row">
                <div className="designer-col col-12">
                  <h2 className="join-us__title h1">
                    Присоединяйтесь к&nbsp;нам
                  </h2>
                  <div className="join-us__decor">
                    <img
                      alt="Открытый ноутбук на фоне светло-желтой округлой фигуры"
                      src="/img/join-us/join-us-decor.svg"
                    />
                  </div>
                  <p className="join-us__desc">
                    Если вы подходите по всем пунктам, то смело отправляйте
                    заявку. Будем рады, если вы станете частью нашей дружной
                    команды
                  </p>
                  {!pausedPsychologistRecruiting && (
                    <Button.NewPrimary
                      className="join-us__link"
                      href={beInTeam.clickUp}
                      onClick={() => handleNewOpenFormJob('Join')}
                      rel="noreferrer"
                      size="large"
                      target="_blank"
                      type="externalLink"
                      width="fullScreen"
                    >
                      Подать заявку
                    </Button.NewPrimary>
                  )}
                </div>
              </div>
            </div>
            <img
              alt="Светло-зеленая фигура"
              className="join-us__decor-top"
              src="/img/icons/be-in-team/join-us-decor.svg"
            />
          </section>
        </main>
        <Footer />
      </App>
    </>
  )
}

export default BeInTeam
