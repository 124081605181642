import React from 'react'
import joinUsImg from './img/teammate-decor.svg'
import styled from 'styled-components'
import { ButtonGroup } from './RecrutingButtonGroup/index.styles'
import { DecorateImage } from './BeInTeamActive.styles'
import { Grid } from '../../atoms/Grid'
import { RecrutingButtonGroup } from './RecrutingButtonGroup'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { size } from '../../constants'

const TextBlock = styled.div`
  ${Title.H1} {
    margin-bottom: 24px;
    max-width: 486px;

    @media (max-width: ${size.md}) {
      margin-bottom: 32px;
      width: 100%;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 16px;
    }
  }

  ${Text.Large} {
    max-width: 486px;
    margin-bottom: 32px;

    @media (max-width: ${size.lg}) {
      max-width: 384px;
    }

    @media (max-width: ${size.md}) {
      max-width: 374px;
    }

    @media (max-width: ${size.sm}) {
      width: 100%;
      margin-bottom: 56px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 8px;
    }
  }
`

const OriginalBlock = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <TextBlock>
        <Title.H1>Хотите стать частью команды?</Title.H1>
        <Text.Large>
          Сотрудничество с YouTalk может стать интересным дополнением
          к&nbsp;вашей частной практике и&nbsp;позволит значительно увеличить
          доходы
        </Text.Large>
        <RecrutingButtonGroup />
      </TextBlock>
      <DecorateImage alt="Несколько ребят" src={joinUsImg} />
    </Grid>
  </section>
))`
  padding: 92px 0 168px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${size.lg}) {
    padding: 72px 0 64px;
  }

  @media (max-width: ${size.md}) {
    padding: 40px 0 72px;
  }

  @media (max-width: ${size.sm}) {
    padding: 48px 0 0;

    & ${ButtonGroup} {
      display: none;
    }
  }

  ${Grid} {
    width: 100%;

    @media (max-width: ${size.sm}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`

export default OriginalBlock
