import React from 'react'
import pauseImg from './img/pause.svg'
import styled from 'styled-components'
import { Grid } from '../../atoms/Grid'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { size } from '../../constants'

const TextBlock = styled.div`
  @media (max-width: ${size.sm}) {
    margin-bottom: 56px;
  }

  @media (max-width: ${size.xs}) {
    margin-bottom: 32px;
  }

  ${Title.H1} {
    margin-bottom: 24px;
    max-width: 600px;

    @media (max-width: ${size.md}) {
      margin-bottom: 32px;
      width: 100%;
    }

    @media (max-width: ${size.sm}) {
      margin-bottom: 16px;
    }
  }

  ${Text.Large} {
    width: 409px;

    @media (max-width: ${size.sm}) {
      width: 100%;
    }
  }
`

const PauseBlock = styled(({ className }) => (
  <section className={className}>
    <Grid>
      <TextBlock>
        <Title.H1>Поставили на паузу набор новых психологов</Title.H1>
        <Text.Large>
          Сегодня сохраняем ресурс, чтобы позже наполнить команду новыми лицами.{' '}
          <br /> Узнайте, какие плюсы получают психологи YouTalk и изучите наши
          требования к кандидатам на будущее.
        </Text.Large>
      </TextBlock>
      <img alt="Девушка и знак паузы" src={pauseImg} />
    </Grid>
  </section>
))`
  max-width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${size.md}) {
    padding-top: 40px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 48px;
    padding-bottom: 0;
  }

  @media (max-width: ${size.xs}) {
    padding-top: 24px;
  }

  ${Grid} {
    width: 100%;

    @media (max-width: ${size.sm}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: ${size.xs}) {
      padding-bottom: 40px;
    }

    & img {
      position: absolute;
      left: 600px;
      top: -40px;

      @media (max-width: ${size.lg}) {
        max-width: 468px;
        left: 524px;
        top: 60px;
      }

      @media (max-width: ${size.md}) {
        max-width: 394px;
        left: 354px;
        top: 140px;
      }

      @media (max-width: ${size.sm}) {
        position: relative;
        left: unset;
        top: unset;
        margin: auto;
        width: 374px;
      }

      @media (max-width: ${size.xs}) {
        max-width: 288px;
      }
    }
  }
`

export default PauseBlock
